import React from 'react'

const SplitSection = ({ id, primarySlot, secondarySlot, reverseOrder }) => (
  <section id={id} className="lg:py-16 py-10">
    <div className={`relative container lg:flex-row flex flex-col mx-auto px-16 py-10 items-center`}>
      <div className={`absolute lg:w-3/4 w-full h-full bg-gray-100 border rounded-xl md:block hidden top-0 z-0 ${reverseOrder ? `right-0` : `left-0`}`}/>
      <div className={`lg:w-2/5 w-full z-10`}>{primarySlot}</div>
      <div className={`lg:w-3/5 w-full lg:mt-0 mt-10 z-10 ${reverseOrder && `order-last lg:order-first`}`}>
        {secondarySlot}
      </div>
    </div>
  </section>
)

export default SplitSection
