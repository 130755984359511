import React from 'react'
import { Link } from 'gatsby'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSubway, faLaptop, faServer, faCheck } from '@fortawesome/free-solid-svg-icons'
import Layout from '../components/layout/Layout'
import SplitSection from '../components/SplitSection'
import Clients from '../components/Clients'
import Card from '../components/Card'
import Image from '../components/Image'
import Button from '../components/Button'

import Fade from 'react-reveal/Fade';
import SwiperCore, { Navigation, Pagination, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.min.css';

// install Swiper modules
SwiperCore.use([Navigation, Pagination, Autoplay]);

const Index = () => (
  <Layout>

    <section id="home" className="bg-gradient-to-r from-primary-500 to-primary-200">
      <Swiper loop navigation pagination={{ clickable: true }} autoplay={{delay: 10000}} >

        <SwiperSlide>
          <div className="container lg:flex mx-auto px-8 md:py-24 py-16">
            <div className="text-center lg:text-left lg:w-1/2">
              <div className="text-4xl lg:text-5xl xl:text-5xl text-white font-bold leading-none tracking-tight">
                <h1>Leading Edge</h1>
                <h1>Software Development</h1>
              </div>
              <p className="text-sm sm:text-lg mt-6 text-white lg:pr-12 sm:pr-0">
                Advanced software solutions improving operational productivity and effectiveness 
                in the rail industry customised to suit the requirements of the business.
              </p>
              <p className="mt-8 md:mt-12">
                <Link to="/about">
                  <Button size="lg">Learn More</Button>
                </Link>
              </p>
            </div>
            <div className="lg:w-1/2 lg:pt-0 pt-8 z-100">
              <Image src="saas.png" alt="Developer clipart" disableLightBox={true} />
            </div>
          </div>
        </SwiperSlide>

        <SwiperSlide>
          <div className="container lg:flex mx-auto px-8 md:py-24 py-16">
            <div className="text-center lg:text-left lg:w-1/2">
              <div className="text-4xl lg:text-5xl xl:text-5xl text-white font-bold leading-none tracking-tight">
                <h1>Graphical Train</h1>
                <h1>Management System</h1>
              </div>
              <p className="text-sm sm:text-lg mt-6 text-white lg:pr-12 sm:pr-0">
                ViziRail is a graphically orientated software tool for the planning, scheduling and
                monitoring of rail traffic. The integrated software modules cover the train operating
                business cycle from long term scheduling through to day of operation monitoring and
                historical reporting on actual train performance.
              </p>
              <p className="mt-8 md:mt-12">
                <Link to="/vizirail">
                  <Button size="lg">Learn More</Button>
                </Link>
              </p>
            </div>
            <div className="lg:w-1/2 z-100">
              <Image src="banner-image-3.png" alt="laptop and phone" disableLightBox={true} />
            </div>
          </div>
        </SwiperSlide>

      </Swiper>
    </section>

    <div className="bg-black h-4" />

    <section id="clients" className="md:pt-8 md:pb-16 pt-16 pb-16">
      <Clients id={'client-list'} />
    </section>

    <section id="features" className="py-20 bg-gray-100 border border-gray-300 shadow-inner overflow-hidden">
      <div className="container mx-auto text-center">
        <h2 className="md:text-xl font-semibold tracking-wide text-primary-500">OUR SOLUTIONS</h2>
        <h2 className="md:text-5xl font-semibold">Products & Services</h2>
        <p className="md:text-xl font-light pt-2">
          A range of software solutions are available. Each solution can be tailored to meet your
          business needs.
        </p>
        <div className="grid lg:grid-cols-3 md:grid-cols-1 grid-cols-1 mt-12 md:p-0 p-4">

          <Fade bottom>
            <div className="mb-8 px-2 h-full lg:pb-0 md:pb-6 pb-6">
              <Card className="relative h-full">
                <FontAwesomeIcon icon={faSubway} size="4x" className="text-primary-400" />
                <p className="font-semibold text-xl text-black mt-2">ViziRail</p>
                <p className="font-light mt-4 mb-12">
                  Train scheduling and monitoring software covering the complete train operating life
                  cycle.
                </p>
                <div className="absolute inset-x-0 bottom-0 pb-8">
                  <Link to="/vizirail">
                    <Button size="lg">See More</Button>
                  </Link>
                </div>
              </Card>
            </div>
          </Fade>

          <Fade bottom delay={100}>
            <div className="mb-8 px-2 h-full lg:pb-0 md:pb-6 pb-6">
              <Card className="relative h-full">
                <FontAwesomeIcon icon={faLaptop} size="4x" className="text-primary-400" />
                <p className="font-semibold text-xl text-black mt-2">DTPOS</p>
                <p className="font-light mt-4 mb-12">
                  A web-based tool that allows Rail Transport Operators (RTOs) to communicate to the
                  Rail Infrastructure Managers (RIMs) electronically and receive feedback in real
                  time.
                </p>
                <div className="absolute inset-x-0 bottom-0 pb-8">
                  <Link to="/dtpos">
                    <Button size="lg">See More</Button>
                  </Link>
                </div>
              </Card>
            </div>
          </Fade>

          <Fade bottom delay={200}>
            <div className="mb-8 px-2 h-full lg:pb-0 md:pb-6 pb-6">
              <Card className="relative h-full">
                <FontAwesomeIcon icon={faServer} size="4x" className="text-primary-400" />
                <p className="font-semibold text-xl text-black mt-2">ViziRail Web Gateway</p>
                <p className="font-light mt-4 mb-12">
                  A gateway allowing Rail Infrastructure Managers (RIMs) & Rail Transport Operators 
                  (RTOs) to communicate via Web UI or electronic interfaces. Includes DTPOS, VizOP & VizRIM. 
                </p>
                <div className="absolute inset-x-0 bottom-0 pb-8">
                  <Link to="/webportal">
                    <Button size="lg">See More</Button>
                  </Link>
                </div>
              </Card>
            </div>
          </Fade>

          <Fade bottom delay={300}>
            <div className="mb-8 px-2 h-full lg:pb-0 md:pb-6 pb-6 hidden">
              <Card className="relative h-full">
                <FontAwesomeIcon icon={faServer} size="4x" className="text-primary-400" />
                <p className="font-semibold text-xl text-black mt-2">VizOP</p>
                <p className="font-light mt-4 mb-12">
                  A portal which provides an Integration layer between Rail Transport Operators (RTOs)
                  and DTPOS allowing for information to be exchanged electronically.
                </p>
                <div className="absolute inset-x-0 bottom-0 pb-8">
                  <Link to="/webportal#vizrim">
                    <Button size="lg">See More</Button>
                  </Link>
                </div>
              </Card>
            </div>
          </Fade>

        </div>
      </div>
    </section>

    <section id="overview" className="md:py-20 py-10 md:pb-0 md:pt-20 overflow-visible"> 
      <Fade bottom>
        <SplitSection
          primarySlot={
            <div className="xl:pr-16 lg:pr-16 md:pr-0">
              <h2 className="lg:text-xl font-semibold text-primary-500">OUR SOLUTIONS</h2>
              <h3 className="text-3xl font-semibold leading-tight">
                Integrate Network Planning and Monitoring
              </h3>
              <p className="mt-6 text-lg font-light mb-6">
                Scheduling is made easy with point-and-click manipulation of train schedules on
                time-distance graphs and schematic track diagrams whilst the collection of real time
                running data make monitoring a breeze for delay attribution, incident recording and
                on-time running reports.
              </p>
              <div className="md:grid md:grid-cols-2 grid-cols-1">
                <div className="checkboxes border border-gray-400 md:w-auto w-full">
                  <FontAwesomeIcon icon={faCheck} />
                  <span className="pl-2 text-black font-semibold md:text-lg">Live Graph</span>
                </div>
                <div className="checkboxes border border-gray-400 md:w-auto w-full">
                  <FontAwesomeIcon icon={faCheck} />
                  <span className="pl-2 text-black font-semibold md:text-lg">Reporting</span>
                </div>
                <div className="checkboxes border border-gray-400 md:w-auto w-full">
                  <FontAwesomeIcon icon={faCheck} />
                  <span className="pl-2 text-black font-semibold md:text-lg">Dashboard</span>
                </div>
                <div className="checkboxes border border-gray-400 md:w-auto w-full">
                  <FontAwesomeIcon icon={faCheck} />
                  <span className="pl-2 text-black font-semibold md:text-lg">Train Map</span>
                </div>
              </div>
            </div>
          }
          secondarySlot={
            <Fade bottom delay={100}>
              <div className="h-full w-full border border-gray-300 rounded-xl shadow-xl overflow-hidden">
                <Image src="Live Graph Tab.png" alt="ViziRail Live Graph" className="border" />
              </div>
            </Fade>
          }
        />
      </Fade>

      <Fade bottom>
        <SplitSection
          reverseOrder
          primarySlot={
            <div className="xl:pl-16 lg:pl-16 md:pl-0">
              <h2 className="lg:text-xl font-semibold text-primary-500">OUR SOLUTIONS</h2>
              <h3 className="text-3xl font-semibold leading-tight">
                Improve Visibility and Communication between Network Managers and Operators.
              </h3>
              <p className="mt-6 text-lg font-light mb-6">
                Request paths or train alterations whilst also entering loads for the trains. Receive
                real time approvals for the requests entered as well as visibility of train position
                and complete train schedules.
              </p>
              <div className="md:grid md:grid-cols-2 grid-cols-1">
                <div className="checkboxes border border-gray-400 md:w-auto w-full">
                  <FontAwesomeIcon icon={faCheck} />
                  <span className="pl-2 text-black font-semibold md:text-lg">Path Requests</span>
                </div>
                <div className="checkboxes border border-gray-400 md:w-auto w-full">
                  <FontAwesomeIcon icon={faCheck} />
                  <span className="pl-2 text-black font-semibold md:text-lg">Consists</span>
                </div>
                <div className="checkboxes border border-gray-400 md:w-auto w-full">
                  <FontAwesomeIcon icon={faCheck} />
                  <span className="pl-2 text-black font-semibold md:text-lg">Schedules</span>
                </div>
                <div className="checkboxes border border-gray-400 md:w-auto w-full">
                  <FontAwesomeIcon icon={faCheck} />
                  <span className="pl-2 text-black font-semibold md:text-lg">Train Maps</span>
                </div>
              </div>
            </div>
          }
          secondarySlot={
            <Fade bottom delay={100}>
              <div className="h-full w-full border border-gray-300 rounded-xl shadow-xl overflow-hidden">
                <Image src="Map Tooltip.png" alt="DTPOS Map" className="border"/>
              </div>
            </Fade>
          }
        />
      </Fade>
    </section>
    
  </Layout>
)
export default Index